import React from 'react';
import { graphql } from 'gatsby';
import { arrayOf, object, shape, string } from 'prop-types';

import { Footer, Hero, SEO, VinForm } from '../../components';

const CompatibilityPage = ({ data }) => {
  const { seo, path, heading, subheading, headerImage, theme } = data.allGraphCmsPage.edges[0].node;

  return (
    <>
      <SEO seo={seo} path={path} />
      <Hero heading={heading} subheading={subheading} image={headerImage} theme={theme} />
      <VinForm />
      <Footer />
    </>
  );
};

export default CompatibilityPage;

CompatibilityPage.propTypes = {
  data: shape({
    allGraphCmsPage: shape({
      edges: arrayOf(
        shape({
          node: shape({
            path: string.isRequired,
            heading: string.isRequired,
            subheading: string.isRequired,
            headerImage: shape({
              // eslint-disable-next-line react/forbid-prop-types
              gatsbyImageData: object.isRequired,
            }),
            seo: shape({
              title: string.isRequired,
              metaDescription: string.isRequired,
            }).isRequired,
            theme: string,
          }).isRequired,
        }).isRequired,
      ).isRequired,
    }).isRequired,
  }).isRequired,
};

export const CompatibilityPageQuery = graphql`
  query CompatibilityPage {
    allGraphCmsPage(filter: { path: { eq: "compatibility" } }) {
      edges {
        node {
          heading
          subheading
          path
          headerImage {
            gatsbyImageData(placeholder: NONE)
          }
          seo {
            title
            metaDescription
          }
          theme
        }
      }
    }
  }
`;
